<template>
  <div>
    <el-dialog
      :visible.sync="addDialog"
      :title="mode == 'edit' ? '修改课程' : '新增课程'"
      width="80%"
    >
      <el-form
        :model="form"
        label-width="auto"
        style="display: flex; justify-content: space-around; flex-wrap: wrap"
        ref="myForm"
        :rules="rules"
      >
        <div :style="ks ? 'width: 100%' : 'width: 35%'">
          <el-form-item label="课程类型">
            <el-select v-model="course_type" @change="changeCourseType">
              <el-option value="haimacourse" label="海马课包"></el-option>
              <el-option value="telent" label="天赋课"></el-option>
              <!-- <el-option value="promote_course" label="推广展示课包"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="定制课程" v-if="course_type == 'haimacourse'">
            <el-checkbox v-model="userclass"></el-checkbox>
          </el-form-item>
          <el-form-item label="课程名称" prop="title" v-if="!userclass">
            <el-input v-model="form.title" :placeholder="temp.title"></el-input>
          </el-form-item>
          <el-form-item label="课程类目" prop="categories">
            <el-radio-group v-model="form.categories"  @change="changeCateType">
              <el-radio label="体育运动">体育运动</el-radio>
              <el-radio label="休闲生活">休闲生活</el-radio>
              <el-radio label="夜校">夜校</el-radio>
              <!-- <el-radio label="陪练">陪练</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上课方式">
            <el-radio-group v-model="form.teach_type">
              <el-radio :label="1">到店</el-radio>
              <el-radio :label="2">上门</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="课程类目">
            <el-select
              v-model="form.categories_child"
              :placeholder="temp.categories_child"
              clearable
            >
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程类型">
            <el-radio-group v-model="form.commodity_type" @change="changeCateType">
              <el-radio label="正式课">普通课程</el-radio>
              <el-radio label="高端商务">高端商务</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="机构城市" >
             <el-cascader
            clearable
            v-model="queryCity"
            :options="cityoptions"
            :props="querycityProps"
            placeholder="筛选条件，不做为课程信息"
          ></el-cascader>
            </el-form-item>
          <el-form-item
            label="绑定机构"
            required
            v-if="mode == 'edit'"
            key="mec1"
          >
            <el-select
              v-model="mec_id"
              :placeholder="mec_name"
              filterable
              clearable
              remote
              :remote-method="searchOrgName"
              @change="changeMec"
            >
              <el-option
                v-for="(item, index) in mechanism_name_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
                @click.native="getAddress(item)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定机构" required v-else key="mec2">
            <el-select
              v-model="select_mechanism"
              collapse-tags
              multiple
              :placeholder="
                !form.map
                  ? '输入机构名称或手机号'
                  : form.map.masterMechanismEntity.mechanism_name
              "
              filterable
              clearable
              remote
              :remote-method="searchOrgName"
              @change="changeMec"
            >
              <el-option
                v-for="(item, index) in mechanism_name_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
                @click.native="getAddress(item)"
              ></el-option>
            </el-select>
            <el-checkbox
              style="margin-left: 6px"
              v-model="checked"
              @change="selectAll"
              >全选</el-checkbox
            >
          </el-form-item>
          <el-form-item label="快捷填写" v-if="course_type == 'haimacourse'">
            <el-select
              placeholder="请输入相似课程的名字"
              value-key="id"
              v-model="simple_course"
              clearable
              remote
              filterable
              :remote-method="searchCourseName"
              @change="courseSimpleChange"
            >
              <el-option
                v-for="item in simpleCourseLIst"
                :key="item.id"
                :label="item.title"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="原课程" v-if="userclass">
            <el-select
              :placeholder="form.title || '请选择课程'"
              value-key="id"
              v-model="old_title"
              clearable
              @change="courseChange"
            >
              <el-option
                v-for="item in MecCourseList"
                :key="item.id"
                :label="item.title"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程名称" prop="title" v-if="userclass">
            <el-input v-model="form.title" :placeholder="temp.title"></el-input>
          </el-form-item>
          <el-form-item label="绑定用户" v-if="userclass">
            <el-select
              v-model="selected_user"
              :placeholder="bind_user"
              value-key="user_id"
              filterable
              remote
              :remote-method="getUser"
              clearable
            >
              <el-option
                v-for="item in userList"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            key="cn"
            required
            label="课节数"
            prop="course_num"
            v-if="course_type == 'telent'"
          >
            <el-input v-model="form.course_num" placeholder="课节数">
            </el-input>
          </el-form-item>
          <el-form-item
            key="fcn"
            required
            label="课节数"
            prop="formal_course_num"
            v-else
          >
            <el-input placeholder="正课节数" v-model="form.formal_course_num">
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="天赋课节数" v-if="course_type == 'haimacourse'">
            <el-input
              placeholder="天赋课节数，没有不填或填0"
              v-model="form.settlement_course"
            >
            </el-input>
          </el-form-item> -->
          <el-form-item label="结算方式">
            <el-radio-group v-model="form.settlement_type">
              <el-radio :label="1">单节结算</el-radio>
              <el-radio :label="2">一次性结算</el-radio>
            </el-radio-group>
          </el-form-item>
           <el-form-item label="是否团课">
            <el-radio-group v-model="form.is_group" @change="groupChange">
              <el-radio :label="true">团课</el-radio>
              <el-radio :label="false">非团课</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="单节市场价"
            v-if="
              course_type != 'telent' && !is_telent && form.categories != '夜校'
            "
            key="single_amout"
          >
            <el-input v-model="form.single_amout"></el-input>
            <span>元</span>
            <div v-if="course_type == 'promote_course'">
              推广展示课包可不设置价格相关参数
            </div>
          </el-form-item>
          <el-form-item
            label="门店市场总价"
            v-if="course_type == 'telent' || is_telent"
          >
            <el-input v-model="form.amout" placeholder="市场总价"> </el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="总价" v-if="userclass">
            <el-input v-model="form.discount_amout" placeholder="价格，如3500">
            </el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item
            label="总结算价"
            prop="settlement_cash"
            v-if="form.settlement_type == 2"
            key="t_s"
          >
            <el-input  type="Number" v-model.number="form.settlement_cash" placeholder="总结算价">
            </el-input>
          </el-form-item>
          <el-form-item
            label="单节结算价"
            prop="settlement_cash"
            v-else
            key="s_s"
          >
            <el-input
             type="Number"
              v-model.number="form.settlement_cash"
              placeholder="单节结算价,天赋课为0"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="系统推荐最低价" v-if="form.system_price">
            {{ form.system_price }}元(使用消费金后)
          </el-form-item>
          <el-form-item label="门店总价">
            <el-input
              v-model="form.amout"
              placeholder="对外展示的原价"
            ></el-input>
          </el-form-item>
          <el-form-item label="美团价格">
            <el-input
              v-model="form.mt_price"
              placeholder="美团价格总价"
            ></el-input>
          </el-form-item>
          <el-form-item label="大众价格">
            <el-input
              v-model="form.dz_price"
              placeholder="大众点评总价"
            ></el-input>
          </el-form-item>
          <p>课程售价为系统推荐价格，美团价格，大众价格的最低价格</p>
          <el-form-item label="课程售价" required prop="discount_amout">
            <div class="flex">
              <el-input v-model="form.discount_amout"></el-input>
              <el-button
                type="text"
                @click="calcSalePrice"
                v-if="form.categories != '夜校' && form.settlement_type == 1 && course_type != 'telent' "
                >计算价格</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="会员价" v-if="form.is_group" key="v1">
             <el-input v-model="form.vip_price1"></el-input>
             <div>商会会员卡的价格</div>
          </el-form-item>
           <el-form-item label="会员超低价" v-if="form.is_group" key="v2">
             <el-input v-model="form.vip_price2"></el-input>
              <div>海马会员卡的价格</div>
          </el-form-item>
          <el-form-item label="可用消费金" v-if="form.categories != '夜校'">
            <el-input placeholder="消费金" v-model="form.consume"></el-input>
          </el-form-item>
          <el-form-item label="单节付服务费" v-if="form.can_single">
            <el-input
              type="Number"
              placeholder="单节付服务费"
              v-model="form.single_service_ratio"
            ></el-input
            >%
          </el-form-item>
          
          <el-form-item label="定金" v-if="form.is_group" key="deposit">
            <el-input
              type="Number"
              placeholder="10%填0.1"
              v-model.number="form.deposit"
            ></el-input>
            <span>按总价的比例</span>
          </el-form-item>

          <el-form-item label="选择地区" key="city" v-if="mode == 'edit'">
            <addr-select
              @getform="addMecAddr"
              :addr="{
                province: temp.province,
                city: temp.city,
                district: temp.district,
              }"
            ></addr-select>
          </el-form-item>
          <el-form-item label="城市" v-if="mode == 'edit'">
            <el-cascader
              v-model="cityList"
              :options="cityoptions"
              :props="cityProps"
              :placeholder="cityList[1]"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="地址" v-if="mode == 'edit'">
            <el-input v-model="form.venues_addr"></el-input>
          </el-form-item>
        </div>

        <div :style="ks ? 'width: 100%' : 'width: 30%'">
          <el-form-item label="课程类型">
            <el-radio-group v-model="form.is_recommend">
              <el-radio :label="false">普通课程</el-radio>
              <el-radio :label="true">优选课程</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="排课类型" prop="appointment_type">
            <el-radio-group v-model="form.appointment_type">
              <el-radio label="fixed_scheduling">固定</el-radio>
              <el-radio label="appointment">预约</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="支持单节付">
            <el-radio-group v-model="form.can_single">
              <el-radio :label="true">支持</el-radio>
              <el-radio :label="false">不支持单节付</el-radio>
            </el-radio-group>
          </el-form-item>

      
          <el-form-item label="课程提示">
            <el-input v-model="form.label"></el-input>
          </el-form-item>
          <el-form-item label="封面" required>
            <avatar-uploader
              @getImgUrl="getCover"
              :url="form.face_url"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="简介" required>
            <el-input
              type="textarea"
              v-model="form.introduction_content"
              placeholder="简介"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="正式课详情">
            <el-input
              type="textarea"
              v-model="form.introduction_text"
              placeholder="正式课详情"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="天赋课详情">
            <el-input
              type="textarea"
              v-model="form.introduction_talent"
              placeholder="天赋课详情"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="课程介绍图片" required>
            <images-uploader
              @getImgUrl="getProductImg"
              :urls="form.introduction_url"
              :clear="if_clear"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="优惠金额比例" v-if="form.categories == '夜校'" v-permission="'settingCouponRatio'">
            <el-radio-group v-model="c_ratio">
              <el-radio
                :label="r.value"
                v-for="(r, i) in coupon_ratios"
                :key="i"
                >{{ r.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="赠送优惠券" v-if="form.categories == '夜校'">
            <p>
              当前优惠券：{{form.send_coupon_name || '无'}}
            </p>
            <el-select
              v-model="form.send_coupon"
              filterable
              remote
              :remote-method="getCoupByName"
              clearable
              value-key="id"
              @change="changeCoupon"
            >
              <el-option
                v-for="item in coupons"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button type="text" @click="settingCoupon">生成优惠券</el-button>
            <p>用户购买课程后赠送的优惠券</p>
          </el-form-item>
          <el-form-item label="班级人数" required="" prop="connect_peoplenum">
            <el-input
              type="number"
              v-model="form.connect_peoplenum"
              placeholder="班级人数"
            ></el-input
            ><span>人</span>
          </el-form-item>
          <el-form-item label="课时长" required="" prop="length_of_lesson">
            <el-input
              v-model="form.length_of_lesson"
              placeholder="课时长"
            ></el-input
            ><span>分钟</span>
          </el-form-item>
          <el-form-item label="有效期" required="" prop="duration">
            <el-input v-model="form.duration" placeholder="有效期"></el-input
            ><span>月</span>
          </el-form-item>
          <el-form-item label="适用年龄" required="" prop="apply_age">
            <el-input v-model="form.apply_age"></el-input>
          </el-form-item>
          <el-form-item label="适用人群" required="" prop="school_age">
            <el-select v-model="form.school_age" :placeholder="temp.school_age">
              <el-option
                v-for="(item, index) in school_age_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="mode != 'check'">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button v-if="mode == 'add'" type="primary" @click="submitForm()"
          >新 增</el-button
        >
        <el-button v-if="mode == 'edit'" type="primary" @click="updateForm()"
          >修 改</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="pass">审核通过</el-button>
        <el-button type="danger" @click="refuse">审核拒绝</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { ObjectChangedValue } from "@/js/utils";
import { courseRule } from "./courseRule";
export default {
  props: {
    courseData: {
      type: Object,
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      queryCity:[],
      ks: false,
      selected_user: "",
      userclass: false,
      type: "mechanism_offline",
      addDialog: false,
      isLoading: true,
      if_clear: false,
      if_new: false,
      activeName: "first",
      userList: [],
      mec_id: "",
      // 搜索
      mechanism_id: null,
      experience_course: [],
      status: 2,
      mechanism_name_list: [],
      updateCourseDialog: false,
      mechanism_type: "",
      categories_child_list: [],
      haveTelente: true,
      title: null,
      master_name: "",
      course_type: "haimacourse",
      cardLevel: [],
      allCardLevel: [],
      study_type: "mechanism_offline",
      timeoutID: null,
      temp: {},
      restricted_exchange_count: null,
      form: {
        categories: "体育运动",
        can_single: true,
        single_service_ratio: 0,
        teach_type: 1,
      },
      form1: {},
      chooseId: "",
      old_title: "",
      mec_name: "",
      bind_user: "",
      venues_list: [],
      rules: courseRule,
      card_type: [],
      updateDialog: false,
      age_type_list: [],
      school_age_list: [],
      course_num: null,
      cancel: null,
      tea_list: [],
      exdialogVisible: false,
      oldPoint: {},
      curCourse: {},
      MecCourseList: {},
      is_recommend: false,
      multi_course_num: false,
      cityoptions: [],
      querycityProps: {
           checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      select_mechanism: [],
      checked: false,
      small_price: 0,
      can_consume: 0,
      is_telent: false,
      simple_course: "",
      simpleCourseLIst: {},
      managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
      coupons: [],
      c_ratio: 0.2,
      coupon_ratios: [
        {
          value: 0.2,
          label: "20%",
        },
        {
          value: 0.25,
          label: "25%",
        },
        {
          value: 0.3,
          label: "30%",
        },
        {
          value: 0.33,
          label: "33%",
        },
      ],
    };
  },

  watch: {
    "form.mt_price"(nv, ov) {
      this.calcPrice();
    },
    "form.dz_price"(nv, ov) {
      this.calcPrice();
    },
    courseData: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        if (this.mode != "add") {
          this.editCourse();
        }
      },
    },
    mode: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        if (val == "add") {
          this.form = {
            categories: "体育运动",
            can_single: true,
            single_service_ratio: 0,
            teach_type: 1,
            settlement_type: 1,
          };
          this.select_mechanism = [];
        }
      },
    },
  },
  methods: {
    groupChange(v){
        console.log(v);
        if(!v){
          this.form.deposit = 0
          if(this.form.discount_amout){
              this.form.vip_price1 = this.form.discount_amout
              this.form.vip_price2 = this.form.discount_amout
          }else{
              this.form.vip_price1 = null
              this.form.vip_price2 = null
          }
        }else{
          this.$set(this.form,'deposit',0.1)
        }
    },
    checkPrice(){
        if(this.form.is_group && !this.form.deposit){
             this.$message('请填写定金')
            return false
        }
      if(this.form.deposit && this.form.deposit > 0.2){
             this.$message('定金不能大于20%')
            return false
        }
    if(this.form.settlement_cash == 0){
      return true
    }

      let settle = 0
        if (this.form.settlement_type == 1) {
        settle = this.form.settlement_cash * (this.form.formal_course_num || this.form.course_num);
      } else if (this.form.settlement_type == 2) {
        settle = this.form.settlement_cash;
      }
     
      if( Number(this.form.discount_amout) >= Number(settle) ){
          return true
      }else{
        this.$message('结算金额不能大于售价')
        return false
      }
     
    },
    //设置夜校的优惠券
    settingCoupon() {
      //优惠券金额最多为利润的1/3
      let profit = 0;
      if (!this.form.discount_amout) {
        this.$message("请填写课程售价");
        return;
      }
      if (!this.form.settlement_cash) {
        this.$message("请填写结算价");
        return;
      }
      if (this.form.settlement_type == 1) {
        if (!this.form.formal_course_num) {
          this.$message("请填写课节数");
          return;
        }
        profit =
          this.form.discount_amout -
          this.form.settlement_cash * this.form.formal_course_num;
      } else if (this.form.settlement_type == 2) {
        profit = this.form.discount_amout - this.form.settlement_cash;
      }
      let max = profit * this.c_ratio;
      let n = Math.floor(max / 5);
      let cash = 5 * n;
      if (cash > 0) {
         const loading = this.$loading({
          lock: true,
          text: '优惠券生成中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let url = "/user/commoditycoupon/getBalanceCoupon";
        this.$axios
          .get(url, {
            params: {
              cash: cash,
            },
          })
          .then((res) => {
            loading.close()
            let coupon = res.data.data;
            this.coupons = [coupon];
            this.form.send_coupon = coupon.id;
            this.form.send_coupon_name = coupon.name;
          }).catch(err=>{
             loading.close()
          })
      }
    },

    changeCoupon(v) {
      if (v) {
        let coupon = this.coupons.filter((item) => {
          return item.id == v;
        });
        if (coupon.length > 0) {
          this.form.send_coupon = coupon[0].id;
          this.form.send_coupon_name = coupon[0].name;
          return;
        }
      }
      this.form.send_coupon = 0;
      this.form.send_coupon_name = "";
    },
    getCoupByName(query) {
      this.coupons = [];
      let url = "/user/commoditycoupon/queryListPage";
      this.$axios
        .get(url, {
          params: {
            name: query || null,
            type: "balance",
          },
        })
        .then((res) => {
          this.coupons = res.data.data;
        });
    },
    open() {
      this.addDialog = true;
      this.form.commodity_type = '正式课'
    },
    // 机构全选
    selectAll(v) {
      if (v) {
        this.select_mechanism = this.mechanism_name_list.map((item) => {
          return item.id;
        });
        this.getAddress(this.mechanism_name_list[0]);
      } else {
        this.select_mechanism = [];
      }
    },
    changeCourseType(v) {
      if (v == "telent") {
        this.form.card_type = "优学卡";
        this.$set(this.form, "discount_amout", 99);
        this.$set(this.form, "consume", 99);
        this.$set(this.form, "can_single", false);
      } else {
        this.form.card_type = "专享卡";
      }
      if (v == "promote_course") {
        this.$set(this.form, "settlement_cash", 0);
      }
    },
    getAddress(e) {
      this.cityList = [];
      this.cityList.push(e.province || "");
      this.cityList.push(e.city || "");
      this.cityList.push(e.district || "");
    },
    getUser(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              login_name: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    changeMec(e) {
      if (this.userclass) {
        this.getMecCourse();
      }
    },
    addMecAddr(addr) {
      this.cityList = [];
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
      this.$set(this.form, "venues_addr", addr.address);
      this.cityList.push(addr.province);
      this.cityList.push(addr.city);
      this.cityList.push(addr.district);
    },
    courseChange(v) {
      this.form.bind_id = v.id;
      this.old_title = v;

      
      this.$set(this.form, "categories", v.categories);
      this.$set(this.form, "categories_child", v.categories_child);
      this.$set(this.form, "title", v.title);
      this.$set(this.form, "introduction_content", v.introduction_content);
      this.$set(this.form, "introduction_text", v.introduction_text);
      this.$set(this.form, "introduction_talent", v.introduction_talent);
      this.$set(this.form, "connect_peoplenum", v.connect_peoplenum);
      this.$set(this.form, "length_of_lesson", v.length_of_lesson);
      this.$set(this.form, "duration", v.duration);
      this.$set(this.form, "apply_age", v.apply_age);
      this.$set(this.form, "school_age", v.school_age);
      this.$set(this.form, "introduction_url", v.introduction_url);
      this.$set(this.form, "face_url", v.face_url);
      this.$set(this.form, "course_num", v.course_num);
      this.$set(this.form, "deduction_course_num", v.deduction_course_num);
      this.$set(this.form, "settlement_cash", v.settlement_cash);
      this.$set(this.form, "settlement_course", v.settlement_course);
      this.$set(this.form, "appointment_type", v.appointment_type);
      this.$set(this.form, "formal_course_num", v.formal_course_num);
      this.$set(this.form, "is_recommend", v.is_recommend);
    },
    courseSimpleChange(v) {
      // this.$set(this.form, "title", v.title);
      this.$set(this.form, "introduction_content", v.introduction_content);
      this.$set(this.form, "introduction_text", v.introduction_text);
      this.$set(this.form, "introduction_talent", v.introduction_talent);
      this.$set(this.form, "connect_peoplenum", v.connect_peoplenum);
      this.$set(this.form, "length_of_lesson", v.length_of_lesson);
      this.$set(this.form, "duration", v.duration);
      this.$set(this.form, "apply_age", v.apply_age);
      this.$set(this.form, "school_age", v.school_age);
      this.$set(this.form, "introduction_url", v.introduction_url);
      this.$set(this.form, "face_url", v.face_url);
      this.$set(this.form, "course_num", v.course_num);
      this.$set(this.form, "deduction_course_num", v.deduction_course_num);
      this.$set(this.form, "settlement_cash", v.settlement_cash);
      this.$set(this.form, "settlement_course", v.settlement_course);
      this.$set(this.form, "appointment_type", v.appointment_type);
      this.$set(this.form, "formal_course_num", v.formal_course_num);
      this.$set(this.form, "is_recommend", v.is_recommend);
    },
    editCourse() {
      this.mechanism_name_list = [];
      this.form = Object.assign({}, this.courseData);
      this.getCateList();
      this.$set(
        this.form,
        "formal_course_num",
        this.form.course_num - this.form.settlement_course
      );
      if (this.courseData.card_type == "优学卡") {
        this.course_type = "telent";
      } else {
        this.course_type =
          this.form.type == "promote_course" ? "promote_course" : "haimacourse";
        this.$set(
          this.form,
          "single_amout",
          (this.form.mec_price / this.form.formal_course_num).toFixed(2)
        );
      }

      if (this.courseData.single_service_ratio > 0) {
        this.form.single_service_ratio = (
          this.courseData.single_service_ratio * 100
        ).toFixed(0);
      }

      this.cityList = [];
      this.cityList.push(this.form.province || this.form.province);
      this.cityList.push(this.form.city || this.form.city);
      this.cityList.push(this.form.district || this.form.district);
      if (this.form.is_public_exclusive) {
        this.userclass = true;
      }
      this.mec_name = this.form.map.masterMechanismEntity.mechanism_name;
      this.form.map.masterinfo &&
        (this.bind_user = this.form.map.masterinfo.nick_name);
    },
    //机构的课程信息
    getMecCourse() {
      let url = "user/masterSetPrice/queryByMessage";
      let params = {
        mechanism_id: this.select_mechanism[0] || null,
        status: 2,
        pageSize: 20,
        currentPage: 1,
        type: "mechanism_offline",
        categories_child: this.form.categories_child || null,
      };
      this.$axios({
        url,
        params,
      }).then((res) => {
        if (res.data.data.rows.length > 0) {
          this.MecCourseList = res.data.data.rows;
        } else {
          this.$message({
            type: "info",
            message: "课程查找失败",
          });
        }
      });
    },
    getExperienceCourse(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            categories: "体育运动",
            card_type: "优学卡",
            pageSize: 10,
            title: typeof query == "string" ? query : null,
            mechanism_id: this.select_mechanism[0] || null,
          },
        })
        .then((res) => {
          this.experience_course = res.data.data.rows;
        })
        .catch((err) => {});
    },
    //查询课程
    queryProu() {
      let url = "user/masterSetPrice/queryByMessage";
      let params = {
        mechanism_id: this.curCourse.mechanism_id,
        status: 2,
        title: this.curCourse.title,
        type: this.study_type,
      };
      if (this.course)
        this.$axios({
          url,
          params,
        }).then((res) => {
          if (res.data.data.rows.length > 0) {
            this.studycard_id = res.data.data.rows[0].id;
            this.exchangePoint2Course();
          } else {
            this.$message({
              type: "info",
              message: "课程查找失败",
            });
          }
        });
    },
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.type = "mechanism_offline";
        this.study_type = "mechanism_offline";
        this.getCourseList();
      } else if (tab.index == 1) {
        this.getServiceList();
      }
    },
    pass() {
      if (this.form.settlement_cash === "") {
        this.$message({
          type: "info",
          message: "请填写结算金额",
        });
        return;
      }
     if(this.checkPrice()){
              this.updateForm();
        }
     
    },
    refuse() {
      let url = "/user/masterSetPrice/update";
      this.$axios({
        url,
        method: "post",
        data: { id: this.form.id, status: 1 },
      })
        .then((res) => {
          this.$message({ type: "warning", message: "修改成功!" });
          this.addDialog = false;
          this.$emit("success");
        })
        .catch((err) => {
          this.$message({ type: "error", message: err.data.message });
        });
    },
    calcPrice() {
      if (!this.form.system_price || this.course_type == "telent") {
        return;
      }
       if (this.form.settlement_type == 2) {
        return
      }
      let price = this.form.system_price;
       let settle = 0
       if (this.form.settlement_type == 1) {
        settle = this.form.settlement_cash * this.form.formal_course_num;
      } else if (this.form.settlement_type == 2) {
        settle = this.form.settlement_cash;
      }
      if (this.form.mt_price) {
        if (settle <= this.form.mt_price) {
          price = Math.min(this.form.mt_price, this.form.system_price);
        }
      }
      if (this.form.dz_price) {
        if (settle <= this.form.mt_price) {
          price = Math.min(this.form.dz_price, price);
        }
      }

      let mec_price = this.form.single_amout * this.form.formal_course_num;
      if (price == this.form.system_price) {
        this.form.amout = mec_price;
        this.can_consume = Math.floor((mec_price - price) / 100) * 100;
        this.$set(this.form, "consume", this.can_consume);
        if (this.can_consume > 0) {
          this.$set(this.form, "discount_amout", mec_price);
        } else {
          this.$set(this.form, "discount_amout", price);
        }
      } else {
        this.$set(this.form, "consume", 0);
        this.$set(this.form, "discount_amout", price);
        this.form.amout = price;
      }

      this.$set(this.form, "mec_price", mec_price);
    },
    calcSalePrice() {
      if (this.course_type != "telent") {
        this.getPoint();
      }
    },
    getPoint() {
      if (!this.form.formal_course_num) {
        this.$message("请填写课节数");
        return;
      }
      if (this.form.single_amout || this.form.amout) {
        if (this.form.settlement_cash || this.settlement_cash.amout) {
          let url = "/user/masterSetPrice/querySettlePoint";
          this.$axios
            .get(url, {
              params: {
                card_type: "专享卡",
                amout: this.form.single_amout,
                settlement_cash: this.form.settlement_cash,
                type: "mechanism_offline",
              },
            })
            .then((res) => {
              let point = res.data.data.point * this.form.formal_course_num;
              let amount = this.form.single_amout * this.form.formal_course_num;
              this.can_consume = Math.floor((amount - point * 10) / 100) * 100;
              if (this.form.is_community || this.form.card_type == "优学卡") {
                this.can_consume = 0;
              }
              this.$set(this.form, "system_price", (point * 10).toFixed(0));
              this.$set(this.form, "consume", this.can_consume);
              this.calcPrice();
            });
        }
      }
    },
    submitForm() {
      if (
        this.form.categories == "夜校" &&
        typeof this.form.deposit != "number"
      ) {
        this.$message("请填写定金");
        return;
      }

      let _this = this;

      this.$refs.myForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          if(this.checkPrice()){
              _this.addNewCourse();
          }
          
        } else {
          console.log("fail");
          _this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    updateForm() {
      let _this = this;
      this.$refs.myForm.validate((valid) => {
        if (valid) {
           if(this.checkPrice()){
             _this.updateCourse();
          }
          
        } else {
          _this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    updateCourse() {
      if (!this.form.discount_amout && this.course_type != "promote_course") {
        this.$message("请设置课程售价");
        return;
      }
      if(this.userclass){
        this.form.is_public_exclusive = true
      }
      let url = "/user/masterSetPrice/update";
      this.if_clear = !this.if_clear;
      let data = Object.assign({}, this.form);
      data.card_type = this.course_type == "telent" ? "优学卡" : "专享卡";
      if (this.course_type == "haimacourse") {
        data.course_num =
          parseInt(this.form.formal_course_num) +
          parseInt(this.form.settlement_course || 0);
      } else if (this.course_type == "telent") {
        data.course_num = this.form.course_num ? this.form.course_num : 1;
      } else {
        data.course_num = this.form.formal_course_num || this.form.course_num;
      }

      data.deduction_course_num = this.form.deduction_course_num || 1;
      data.amout = this.form.single_amout * data.course_num;
      if(this.selected_user){
      data.activity_list = this.selected_user;
      }
      data.discount_amout = data.amout;
      if (!this.courseData.is_community) {
        data.commodity_type = data.card_type == "优学卡" ? "天赋课" : data.commodity_type;
      }
      data.amout = this.form.amout;
      data.discount_amout = this.form.discount_amout;
      data.consume = this.form.consume;
      data.province = this.cityList[0];
      data.city = this.cityList[1];
      data.district = this.cityList[2];
      //天赋课
      if (this.course_type == "telent") {
        data.settlement_course = this.form.course_num;
        data.default_discount_price = 99;
        data.discount_amout = 99;
        data.consume = 99;
      }
      //定制课
      if (this.userclass == true) {
        data.consume = 0;
        data.is_public_exclusive = true;
        data.discount_amout = this.form.discount_amout;
        data.amout = this.form.discount_amout;
        data.default_discount_price = this.form.discount_amout;
      }
      if (this.course_type == "promote_course") {
        data.course_num = this.form.formal_course_num;
        data.type = "promote_course";
      }
      if (this.form.single_service_ratio > 0) {
        data.single_service_ratio = (
          this.form.single_service_ratio / 100
        ).toFixed(2);
      }
      this.mec_id && (data.mechanism_id = this.mec_id);
      let newInfo = ObjectChangedValue(this.courseData, data);
      newInfo.id = this.form.id;
      if (this.mode == "check") {
        newInfo.status = 2;
      }
      newInfo.consume = this.form.consume;
      this.$axios.post(url, newInfo).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.addDialog = false;
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    getTelentFace(v) {
      this.form1.face_url1 = v;
    },
    getTelentIntroduce(v) {
      this.form1.introduction_url1 = v;
    },
    getCover(v) {
      this.form.face_url = v;
    },
    mechanism_name(id) {
      let a = this.mechanism_name_list.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a.mechanism_name;
      } else return {};
    },

    getProductImg(v) {
      // console.log(v);
      this.$set(this.form, "introduction_url", v);
    },
    caculate() {
      this.getServiceLowPoint();
      this.getServiceHeightPoint();
    },
    changeCateType() {
      this.getCateList();
      if (this.form.categories != "体育运动") {
        this.form.can_single = false;
      }
      if (this.form.categories == "夜校") {
        this.form.is_group = true;
      }
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      if (this.form.categories != "体育运动") {
        data.source = this.form.categories;
      }

      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;

          // this.typeTotal = res.data.data.total;
        });
    },
    changeGoodType(v) {
      this.mechanism_id = null;
      this.mechanism_name_list = [];
      this.searchOrgName();
    },
    addNewCourse() {
      // 批量新增
      if (!this.form.discount_amout && this.course_type != "promote_course") {
        this.$message("请设置课程售价");
        return;
      }
      if(this.userclass){
        this.form.is_public_exclusive = true
      }
      let url = "/user/masterSetPrice/batchInsert";
      this.if_clear = false;
      let data = Object.assign({}, this.form);

      data.card_type = this.course_type == "telent" ? "优学卡" : "专享卡";
      if (this.course_type == "haimacourse") {
        data.course_num =
          parseInt(this.form.formal_course_num) +
          parseInt(this.form.settlement_course || 0);
      } else if (this.course_type == "telent") {
        data.course_num = this.form.course_num ? this.form.course_num : 1;
      } else {
        data.course_num = this.form.formal_course_num || this.form.course_num;
      }

      data.deduction_course_num = this.form.deduction_course_num || 1;

      data.binding_venues_id = 0;
      data.type = "mechanism_offline";
       if(this.selected_user){
      data.activity_list = this.selected_user;
      }
      data.amout = this.form.amout;
      data.discount_amout = this.form.discount_amout;
      data.commodity_type = data.card_type == "优学卡" ? "天赋课" : data.commodity_type;
      data.consume = this.form.consume;
      //天赋课
      if (this.course_type == "telent") {
        data.settlement_course = this.form.course_num;
        data.amout = this.form.amout;
        data.default_discount_price = 99;
        data.discount_amout = 99;
        data.consume = 99;
        data.can_single = false;
      }

      //定制课
      if (this.userclass == true) {
        if (!this.old_title) {
          this.$message({ message: "请选择原课程，如果没有请添加新课程" });
          return;
        }
        data.consume = 0;
        data.type = "custom_offline";
        data.discount_amout = this.form.discount_amout;
        data.amout = this.form.discount_amout;
        data.default_discount_price = this.form.discount_amout;
        data.is_conceal = 1;
      }
      //艺术课程
      if (this.form.categories == "艺术课程") {
        data.categories = "艺术课程";
      }

      if (this.course_type == "promote_course") {
        data.course_num = this.form.formal_course_num;
        data.type = "promote_course";
      }

      if (this.form.single_service_ratio > 0) {
        data.single_service_ratio = (
          this.form.single_service_ratio / 100
        ).toFixed(2);
      }

      if (this.managerInfo.type.includes("admin")) {
        data.status = 2;
      } else {
        data.status = 4;
      }

      data.mechanism_ids = this.select_mechanism;
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.addDialog = false;
          this.if_clear = !this.if_clear;
          this.form = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    searchCourseName(query) {
      let url = "user/masterSetPrice/queryByMessage";
      let params = {
        mechanism_id: this.select_mechanism[0] || null,
        status: 2,
        pageSize: 20,
        currentPage: 1,
        type: "mechanism_offline",
        title: query || null,
      };
      this.$axios({
        url,
        params,
      }).then((res) => {
        this.simpleCourseLIst = res.data.data.rows;
      });
    },
    openUpdate(row) {
      this.temp = row;
      row.map.masterMechanismEntity &&
        (this.temp.mechanism_name =
          row.map.masterMechanismEntity.mechanism_name);
      row.map.masterinfo &&
        (this.temp.user_name = row.map.masterinfo.nick_name);
      this.$set(this.form, "is_discount", row.is_discount);
      this.$set(this.form, "is_recommend", row.is_recommend);
      this.$set(this.form, "appointment_type", row.appointment_type);
      this.$set(this.form, "introduction_content", row.introduction_content);
      this.$set(this.form, "introduction_text", row.introduction_text);
      this.$set(this.form, "introduction_talent", row.introduction_talent);
      this.cityList = [];
      this.cityList.push(row.province);
      this.cityList.push(row.city);
      this.cityList.push(row.district);
      this.getMecCourse();

      this.course_type = "haimacourse";

      if (row.card_type == "优学卡") {
        this.course_type = "telent";
      }

      this.mechanism_type = row.type;
      this.updateDialog = true;
    },
    changeCardType(v) {
      let list = [];
      v.forEach((item) => {
        list.push(item[0]);
      });
      this.$set(this.form, "card_type", list.join(","));
    },

    submitCourseForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.addNewService();
        }
      });
    },
    getAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            platform: "年级",
          },
        })
        .then((res) => {
          this.age_type_list = res.data.data.rows;
        });
    },
    getSchoolAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            platform: "学龄",
          },
        })
        .then((res) => {
          this.school_age_list = res.data.data.rows;
        });
    },
    // 机构查询远程查询
    searchOrgName(query) {
      if (query && typeof query == "string") {
        let url = "/user/mastermechanism/queryByMessage";
        let data = {
          type: "teach_paypal",
          pageSize: 100,
          currentPage: 1,
          status: 2,
        };
        if(this.queryCity.length > 0){
          data.province = this.queryCity[0] || null
          data.city = this.queryCity[1] || null
          data.district = this.queryCity[2] || null
        }
        const numberReg = /^\d/;
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        if (this.form.categories == "艺术课程") {
          data.type = "机构商户";
        }
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            // console.log(res);
            this.mechanism_name_list = res.data.data.rows;
          })
          .catch((err) => {});
      }
    },
    changeMechanismType(v) {
      this.mechanism_name_list = [];
    },
  },
  mounted() {
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
    this.getCateList();
    this.getAgeType();
    this.getSchoolAgeType();
    this.getCoupByName();
  },
};
</script>
  
  <style>
.el-table thead tr th {
  background-color: #eaeaea !important;
}

.overhide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-button {
  margin-bottom: 5px;
}

.el-button + .el-button {
  margin-left: 0;
}
</style>
  